<template>
  <div class="virtualBriefcase pageInit">
    <h1 class="pageTitle textColor">Virtual Briefcase</h1>
    <div class="filterContainer">
      <!--<el-select v-model="select" placeholder="">-->
      <!--  <el-option-->
      <!--      v-for="item in options"-->
      <!--      :key="item.value"-->
      <!--      :label="item.label"-->
      <!--      :value="item.value"-->
      <!--  >-->
      <!--  </el-option>-->
      <!--</el-select>-->
      <div class="searchContiner">
        <searchBox style="margin-right: 20px"
                   :show-go="true"
                   @clickGoBtn="clickGoBtn"
                   ref="refSearch"
        ></searchBox>
      </div>
    </div>
    <div class="lists">
      <el-row justify="end" align="center">
        <button class="x-btn borderBtn" @click="removeSelected"
                style="cursor:pointer;margin: 5px 10px;font-size: 14px;width:auto;min-width: 170px">
          Remove Selected
        </button>
        <button class="x-btn" @click="downLoadSelected"
                style="cursor:pointer;margin: 5px 10px;font-size: 14px;width:auto;min-width: 170px">
          Download Selected
        </button>
        <!--<button class="x-btn" @click="downLoadAll"-->
        <!--        style="cursor:pointer;margin: 5px 10px;font-size: 14px;width:auto;min-width: 170px">-->
        <!--  Download All-->
        <!--</button>-->
      </el-row>

      <el-row :gutter="24" style="padding-bottom: 5px">
        <el-col :xs="24" :md="8" :lg="10" class="listTitle">Title</el-col>
        <el-col :md="4" :lg="3" class="listTitle hidden-md-and-down">Event/Meeting</el-col>
        <el-col :md="4" :lg="3" class="listTitle hidden-md-and-down">Date</el-col>
      <!--  <el-col :xs="24" :md="8" :lg="8" class="alignEnd" style="display:flex;flex-wrap: wrap;">-->
      <!--    <button class="x-btn borderBtn" @click="removeSelected"-->
      <!--            style="cursor:pointer;margin: 5px 10px;font-size: 14px;width:auto;min-width: 170px">-->
      <!--      Remove Selected-->
      <!--    </button>-->
      <!--    <button class="x-btn" @click="downLoadSelected"-->
      <!--            style="cursor:pointer;margin: 5px 10px;font-size: 14px;width:auto;min-width: 170px">-->
      <!--      Download Selected-->
      <!--    </button>-->
      <!--    <button class="x-btn" @click="downLoadAll"-->
      <!--            style="cursor:pointer;margin: 5px 10px;font-size: 14px;width:auto;min-width: 170px">-->
      <!--      Download All-->
      <!--    </button>-->
      <!--  </el-col>-->
      </el-row>
      <div class="list" v-for="(item, index) in lists" :key="'list' + index">
        <div class="listContainer">
          <!--<p class="docIcon">-->
          <!--  <img v-if="item.img" :src="item.img" alt=""/>-->
          <!--  <i v-else class="iconfont icon-pdf" style="font-size: 30px;color:gray"></i>-->
          <!--</p>-->
          <el-row :gutter="24" style="width: calc(100% + 30px);">
            <el-col :xs="24" :md="8" :lg="10" style="display: flex;">
              <div class="grid-content">
                <p class="docName">
                  <el-row :gutter="24" style="width: calc(100% + 30px);">
                    <el-row class="grid-content" style="display: flex;align-items: center;">
                      <el-row class="subject">
                        <el-checkbox v-model="item.chosen"></el-checkbox>
                        <el-row style="margin:0 20px" v-if="client=='Aruba'">
                          <el-row v-if="item.img">
                            <img :src="item.img" alt=""/>
                          </el-row>
                          <el-row v-else-if="client=='Aruba'">
                            <img v-if="item.fileType=='pdf'" src="@/assets/imgs/Aruba/resource/fileBlue.png"/>
                            <img v-else src="@/assets/imgs/Aruba/resource/linkBlue.png"/>
                          </el-row>
                        </el-row>
                        <el-row style="margin:0 20px" v-else>
                          <img v-if="item.img" :src="item.img" alt=""/>
                          <i v-else class="iconfont icon-pdf" style="font-size: 30px;color:gray"></i>
                        </el-row>
                        <span class="title">{{ item.title }}</span>
                        <br/>
                      </el-row>
                    </el-row>
                  </el-row>
                </p>
              </div>
            </el-col>
            <el-col :sm="4" :lg="3" class=" hidden-md-and-down">
              <div class="grid-content vertialCenter">
                {{ item.briefCaseName }}
              </div>
            </el-col>
            <el-col :sm="4" :lg="3" class="  hidden-md-and-down">
              <div class="grid-content vertialCenter">
                {{ item.dateCreated }}
              </div>
            </el-col>
            <el-col :xs="24" :md="8" :lg="8" class="alignEnd">
              <div class="grid-content" style="">
                <p class="viewBtn">
                  <button class="x-btn borderBtn" @click="removeBriefcase(item.contentId)" style="margin: 5px 10px;">
                    Remove from Briefcase
                  </button>
                  <!--<button class="x-btn" @click="toggleBriefcase(item.id)" style="margin: 5px 10px;">-->
                  <!--  {{ item.isAdd ? 'Add to Briefcase' : 'Remove from Briefcase' }}-->
                  <!--</button>-->
                  <button class="x-btn" @click="download(item.fileUrl)" style="margin: 5px 10px;">Download</button>
                </p>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
    <div class="paginationContainer">
      <el-pagination
          v-model:currentPage="currentPage"
          v-model:page-size="pageSize"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          background
          :page-sizes="[5, 10, 15, 20,50]"
          layout="prev, pager, next"
          :total="total"
          class="pagination mt-4"
      >
      </el-pagination>
    </div>

  </div>
</template>

<script>
import {
  defineComponent,
  ref,
  reactive,
  toRefs,
  toRaw,
  onBeforeMount,
  onMounted,
} from "vue";

import searchBox from "@/components/tools/search.vue";
import axios from "@/api/http";
import {useStore} from 'vuex';
import {ElMessage} from "element-plus";
import {ElLoading} from 'element-plus';
import {ElMessageBox} from 'element-plus';
import {useRouter} from "vue-router";
import notification from "@/store/notification";
import qs from 'qs'


export default defineComponent({
  name: "virtualBriefcase",
  components: {
    searchBox,
  },
  setup() {
    const router = useRouter();
    const client = localStorage.getItem("eventClient");
    const refSearch = ref(null);//用来查询的时候获取searhBox组件的input的值
    const store = useStore();
    const data = reactive({
      select: '',
      options: [
        {label: "All Tags", value: 1},
      ],
      lists: [
        // {
        //   img: require("../../assets/imgs/icon.jpg"),
        //   name: "Virtual Vendor show overview",
        //   id: 1,
        // },
        // {
        //   img: require("../../assets/imgs/icon.jpg"),
        //   name: "MC Event Tutoral",
        //   id: 1,
        // },
        // {img: require("../../assets/imgs/icon.jpg"), name: "Lorem", id: 1},
      ],
      // 分页
      currentPage: 1,
      pageSize: 5,
      total: 0
    });
    const formatData = (data) => {
      let arr = [];
      data.map(item => {
        let obj = {};
        obj.img = item.img;
        obj.name = item.subject;
        obj.id = item.id;
        obj.link = item.link;
        arr.push(obj);
      })
      return arr
    };
    const getData = (keyword, pageSize, index) => {
      let refKeyword = refSearch.value.input1.trim();
      const loading = ElLoading.service({
        lock: true,
      })
      axios.request({
        method: 'PATCH',
        url: '/virtual/briefcase',
        data: {
          meetingId: 'a7yyxczuty0qpjo2393gmyad',
          pageSize: pageSize || data.pageSize,
          index: index || data.currentPage - 1,
          keyword: keyword || refKeyword
          // meetingId: localStorage.eventsMeetingId,
        }
      }).then(res => {
        console.log("==== res=====", res)
        // console.log("==== res.data=====",res.data)
        // console.log("==== res.data.data=====",res.data.data)
        // console.log("==== res.data.data.data=====",res.data.data.data)
        let result = res.data.result;
        data.lists = res.data.data.data;
        console.log("==== data.lists =====", data.lists)
        data.total = res.data.data.total;
        if (result == 200 && data.lists && data.lists.length > 0) {
          data.lists.map(item => {
            item["chosen"] = false;
            // item["isAdd"] = true;
          })
        }
        let isChange = store.state.notification.notificationChange;
        store.commit("setNotificationChange", !isChange);
        loading.close();
      }).catch(err => {
        console.log(err);
        loading.close();
      })
    }
    const toggleBriefcase = (id) => {
      // console.log("====lists =====",data.lists)
      console.log("==== id=====", id)
      let length = data.lists.length;
      for (let i = 0; i < length; i++) {
        let item = data.lists[i];
        if (item.contentId == id) {
          item.isAdd = !item.isAdd;
          break
        }
      }
    }
    const removeBriefcase = (id) => {
      // console.log("====lists =====",data.lists)
      console.log("==== id=====", id);
      let arr = [];
      arr.push(id);
      let length = data.lists.length;
      for (let i = 0; i < length; i++) {
        let item = data.lists[i];
        if (item.contentId == id) {
          ElMessageBox.confirm(
              'Are you sure you want to remove this item? You may add them again later from their respective events/meetings.',
              'Think Twice',
              {
                confirmButtonText: 'Yes',
                cancelButtonText: 'Cancel',
                type: 'warning',
              }
          ).then(() => {
            const loading = ElLoading.service({
              lock: true,
            })
            axios.request({
              method: 'DELETE',
              url: 'virtual/briefcase',
              data: {
                meetingId: "g9nbpj1o0pa74uussm273vzy",
                contentId: arr,
              }
            }).then(res => {
              data.lists.splice(i, 1);
              loading.close();
            }).catch(err => {
              console.log(err);
              loading.close();
            })
          })

          break
        }
      }
    }
    const download = (fileUrl) => {
      const loading = ElLoading.service({
        lock: true,
      })
      let fileArr = [];
      fileArr.push(fileUrl);
      console.log("====fileArr =====", fileArr)
      axios.request({
        method: 'POST',
        url: '/virtual/getUrlToZip',
        data: {
          file: fileArr
        }
      }).then(res => {
        console.log('res:', res);
        loading.close();
        ElMessageBox.alert('We are currently bundling your items for you into a zip file.When the file is ready you will recieve an email containing a link to download it.', {
          confirmButtonText: 'OK',
        })
      }).catch(err => {
        console.log(err);
      })
    }
    const downLoadAll = () => {
      ElMessageBox.confirm(
          'Download all content items in the current view? Please note that this will only download files, not items that are hyperlinks.',
          {
            confirmButtonText: 'Download',
            cancelButtonText: 'Cancel',
            type: 'warning',
          }
      ).then(res => {
        const loading = ElLoading.service({
          lock: true,
        })
        axios.request({
          method: 'POST',
          url: '/virtual/briefcase/zip',
          data: {}
        }).then(res => {
          loading.close();
          console.log('res:', res);
          ElMessageBox.alert('We are currently bundling your items for you into a zip file.When the file is ready you will recieve an email containing a link to download it.', {
            confirmButtonText: 'OK',
          })
        }).catch(err => {
          loading.close();
          console.log(err);
        })
      })
    }

    const downLoadSelected = () => {
      let lists = toRaw(data.lists);
      console.log("==== lists=====",)
      let arr = [];
      lists.map(item => {
        if (item.chosen) {
          arr.push(item.contentId);
        }
      })
      if (arr.length <= 0) {
        ElMessageBox.confirm(
            'Please select',
            {
              confirmButtonText: 'OK',
              showCancelButton: false,
              type: 'warning',
            }
        )
        return
      }
      console.log("==== arr=====", arr)
      const loading = ElLoading.service({
        lock: true,
      })
      axios.request({
        method: 'POST',
        url: '/virtual/getUrlToZip',
        data: {
          file: arr
        }
      }).then(res => {
        console.log('res:', res);
        loading.close();
        ElMessageBox.confirm(
            'We are currently bundling your items for you into a zip file.When the file is ready you will recieve an email containing a link to download it.',
            {
              confirmButtonText: 'OK',
              showCancelButton: false,
              type: 'warning',
            }
        )
      }).catch(err => {
        loading.close();
        console.log(err);
      })
    }


    const handleSizeChange = (page) => {
      getData();
    }
    const handleCurrentChange = () => {
      getData();
    }
    const clickGoBtn = (keyword) => {
      data.currentPage = 1;
      getData(keyword)
    }
    const removeSelected = () => {
      let lists = toRaw(data.lists);
      console.log("==== lists=====",)
      let arr = [];
      lists.map(item => {
        if (item.chosen) {
          arr.push(item.contentId);
        }
      })
      if (arr.length <= 0) {
        ElMessageBox.confirm(
            'Please select',
            {
              confirmButtonText: 'OK',
              showCancelButton: false,
            }
        )
        return
      }
      console.log("==== arr=====", arr)
      ElMessageBox.confirm(
          'Are you sure you want to remove these items? You may add them again later from their respective events/meetings.',
          'Confirmation',
          {
            confirmButtonText: 'Yes',
            cancelButtonText: 'Cancel',
            type: 'warning',
          }
      ).then(res => {
        const loading = ElLoading.service({
          lock: true,
        })
        axios.request({
          method: 'DELETE',
          url: 'virtual/briefcase',
          data: {
            meetingId: "g9nbpj1o0pa74uussm273vzy",
            contentId: arr,
          }
        }).then(() => {
          loading.close();
          let length = arr.length;
          for (let i = 0; i < length; i++) {
            let item = arr[i];
            let length = data.lists.length;
            for (let j = 0; j < length; j++) {
              let jtem = data.lists[j];
              if (item == jtem.contentId) {
                data.lists.splice(j, 1)
                break
              }
            }
          }
        }).catch(() => {
          loading.close();
        })
      })
    }
    onBeforeMount(() => {
    });
    onMounted(() => {
      getData();
    });
    return {
      ...toRefs(data),
      router,
      client,
      refSearch,
      getData,
      store,
      toggleBriefcase,
      removeBriefcase,
      download,
      handleSizeChange,
      handleCurrentChange,
      clickGoBtn,
      removeSelected,
      downLoadSelected,
      downLoadAll
    };
  },
});
</script>
<style scoped lang='scss'>
.virtualBriefcase {Notification
  .filterContainer {
    display: flex;
    align-items: center;
    //justify-content: space-between;
    justify-content: flex-end;
    margin: 20px 0 20px 0;

    .searchContiner {
      display: flex;
      align-items: center;
      margin-right: 15px;
    }
  }

  .lists {
    padding: 0px 0 20px 0;
    border-top: 1px solid #fff;
    word-break: break-word;

    .list:nth-child(odd) {
      //background: #f2f3f5;
      background: #e8f5f9;
    }

    .list:nth-child(even) {
      background: #fff;
    }

    .listTitle {
      font-size: 16px;
      font-family: OpenSans-Semibold;
    }

    .alignCenter {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .alignStart {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }

    .alignEnd {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    .vertialCenter {
      display: flex;
      align-items: center;
    }

    .list {
      //cursor: pointer;
      .listContainer {
        display: flex;
        align-items: center;
        padding: 15px 0;
        min-height: 40px;

        .grid-content {
          font-size: 14px;
          display: flex;
          align-items: center;
          height: 100%;
        }

        .docIcon {
          width: 200px;
          text-align: center;
        }

        .docName {
          flex: 1;
          font-size: 16px;
          padding-left: 20px;
          .subject{
            img{
              object-fit: contain;
            }
          }
          .title {

          }
        }

        .viewBtn {
          width: auto;

          button {
            background-color: #2dccd3;
            font-size: 14px;
            border: 0;
            border-radius: 15px;
            height: 30px;
            font-family: OpenSans-Semibold;
            cursor: pointer;
            width: auto;
            min-width: 140px !important;
          }
        }
      }
    }
  }

  .paginationContainer {
    margin: 30px auto;

    .pagination {
      display: flex;
      justify-content: center;
    }
  }
}
</style>
<style lang="scss" scoped>
@media screen and (max-width: 992px) {
  .hidden-md-and-down {
    display: none;

  }
}
</style>
